<template>
  <div class="configuration-iframe">
    <!--    <iframe :src="url" ref="iframeRef" @load="onload"></iframe>-->
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount } from 'vue';
import { userCurrentProject } from '@/layout/messageCenter/user-info';

export default defineComponent({
  name: 'ConfigurationImport',
  setup() {
    const staticUrl = `/configuration-studio?projectId=${userCurrentProject.value?.id || 0}`;
    // 'http://dev-configuration.welink.qq.com:1025';

    const iframeRef = ref(null);
    const url = ref('');

    const onload = () => {
      console.log('onload');
    };
    onBeforeMount(() => {
      console.log('this is mount');
    });
    setTimeout(() => {
      url.value = staticUrl;
    }, 10);

    return {
      url,
      iframeRef,
      onload,
    };
  },
});
</script>

<style lang="scss">
.configuration-iframe {
  width: 100%;
  height: calc(100vh - 140px);
  //background-color: rgba(0, 0, 0, 0.2);
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
